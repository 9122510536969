<template>
  <div class="e-card e-rounding shadow-sm">
    <Loader v-if="loading"/>
    <div v-if="data.logoId!==null && ak!==null && ak!=='' && ak!==undefined">
      <img class="logo" :src="bu+'/remote-order/get-logo?logoId='+data.logoId+'&ak='+ak"/>
    </div>
    <Transition>
    <div class="ep-info" v-if="showConfirm">
      {{ $t('confirm.ordered') }}
    </div>
    </Transition>
    <div v-if="!loading">
      <div v-if="state==='list'">
        <div v-show="data.billExists">
          <div class="row" v-if="data.billState.billNum!==''">
          <div v-show="data.billState.type==='temporary'" class="billNum">{{ data.billState.billNum }}</div>
          <div class="title-bold d-flex align-items-center">{{ data.billState.billName }}</div>
        </div>

          <div v-if="typeExists(data.billState.billItems,'created')===true">
            <hr>
            <div class="notFinished">{{ $t('title.ordered') }} </div>
          <pre>
              <table>
              <div v-for="billItem in data.billState.billItems" v-bind:key="billItem.id">
                <div v-if="billItem.orderState == 'created'">
                  {{ billItem.quantity }}
                  x
                  {{ billItem.itemName }}
                  {{ billItem.note }}
                   <span v-for="mod in billItem.modifiers" v-bind:key="mod" >
                    {{ mod }}
                  </span>
                </div>
              </div>
              </table>
              </pre>
          </div>

          <div v-if="typeExists(data.billState.billItems,'finished')===true">
            <hr>
            <div class="finished">{{ $t('title.finished') }}</div>
            <pre>
              <table>
              <div v-for="billItem in data.billState.billItems" v-bind:key="billItem.id">
                <div v-if="billItem.orderState == 'finished'">
                  <b>
                  <i class="fa-solid fa-check"></i>
                  {{ billItem.quantity }}
                  x
                  {{ billItem.itemName }}
                     {{ billItem.note }}
                  <span v-for="mod in billItem.modifiers" v-bind:key="mod" >
                    {{ mod }}
                  </span>
                  </b>
                </div>
              </div>
              </table>
              </pre>
          </div>

          <div v-if="typeExists(data.billState.billItems,'deleted')===true">
            <hr>
            <div class="notFinished">{{ $t('title.delivered') }} </div>
            <pre>
              <table>
              <div v-for="billItem in data.billState.billItems" v-bind:key="billItem.id">
                <div v-if="billItem.orderState == 'deleted'">
                  {{ billItem.quantity }}
                  x
                  {{ billItem.itemName }}
                   {{ billItem.note }}
                   <span v-for="mod in billItem.modifiers" v-bind:key="mod" >
                    {{ mod }}
                  </span>
                </div>
              </div>
              </table>
              </pre>
          </div>
          <hr>
          <div class="total"><b>{{ $t('title.total') }}</b> {{ data.billState.totalBillStr }}</div>
          <hr>
          <div style="margin: 50px"/>
        </div>

        <div v-if="data.customerMessage!==''">
          {{ data.customerMessage }}
          <div style="margin: 20px"/>
        </div>

        <h2 class="h2">{{ $t('title.menu') }}</h2>
        <div style="color: darkred" v-if="data.offerCategories.length ===0">
          {{ $t('missing.items') }}
        </div>

        <div v-for="category in data.offerCategories" v-bind:key="category.categoryName">
          <h4 class="h4">{{ category.categoryName }}</h4>
          <table>
            <tr v-for="item in category.offerItems" v-bind:key="item.itemId">
              <td>
                <button @click="prepareOrder(item)" class="btn btn-secondary mt-1 mb-1 mr-1">
                  {{ $t('button.order') }}
                </button>
              </td>
              <td class="ep-item-name">{{ item.itemName }}</td>
              <td class="ep-item-price">{{ item.itemPriceStr }}</td>
            </tr>
          </table>
          <div style="margin: 30px"/>
        </div>
      </div>

      <div v-if="state==='order'">
        <div class="title">{{ $t('title.order') }}</div>
        <div class="title-bold"> {{ itemToOrder.itemName }} <span v-show="modifiers.length!==0">{{ itemToOrder.itemPriceStr }}</span></div>
        <div style="margin-top: 40px"/>
        <div v-if="modifiers.length===0" class="d-flex">
          <button @click="changeQuantity(-1)" class="btn btn-secondary mr-2 w-25 largeFont">
            -
          </button>
          <div class="ml-auto quantity">
            {{ quantityToOrder }}
          </div>
          <button @click="changeQuantity(1)" class="btn btn-secondary mr-2 w-25 ml-auto largeFont">
            <div style="font-size: xx-large">+</div>
          </button>
        </div>
        <div>
          <div style="padding: 10px; font-size: x-large; margin: auto; width: 50%;" v-for="mod in modifiers" v-bind:key="mod.itemId">
              <input type="checkbox" v-model="mod.checked" v-bind:id="mod.itemId">
              {{ mod.name }} {{ mod.priceStr }}
          </div>
        </div>
        <div style="margin-top: 40px">
          <div>
          {{ $t('title.note') }}
          </div>
          <div>
            <input v-model="note" class="form-control">
          </div>
        </div>
        <div class="d-flex" style="margin-top: 40px">
          <button @click="state='list'" class="btn btn-secondary mr-2 w-50 largeFont">
            {{ $t('button.back') }}
          </button>
          <button @click="makeOrder()" class="btn btn-success mr-2 w-50 ml-auto largeFont">
            {{ $t('button.order') }}
          </button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Loader from "@/components/Loader"
import {baseURL} from '@/config/variables'

export default {
  name: 'Bill',
  components: {Loader},

  data: function () {
    return {
      loading: true,
      state: 'list',
      itemToOrder: null,
      quantityToOrder: 0,
      data: {
        billState: {
          totalBillStr: 0,
          billName: '',
          billNum: '',
          billItems: [],
          lastChange: 0
        },
        offerCategories: [],
        logoId: null,
        customerMessage: '',
        billExists: false
      },
      bu: baseURL,
      ak: localStorage.apiKey,
      startTime: Date.now(),
      timeout : 4000,
      showConfirm : false,
      note : '',
      permanentBillId: null,
      type: 'unknown',
      permanentBillStartTime: null
    }
  },

  mounted: function () {
    const vm = this;
    vm.updateDataFromParams();
    //start to count time, we limit time for order
    vm.updateTime();
    if(vm.type==='permanent') {
      //hide original path from the history
      history.replaceState(
          {},
          null,
          '/#/pbe'
      )
    }

    vm.refresh();

    setTimeout(this.ping,this.timeout);

    this.startTime = Date.now();

  },
  destroyed: function () {
  },

  methods: {
    refresh: function () {
      const vm = this;
      vm.loading = true;
      this.$http.post('/remote-order/get-remote-bill', {
        billId: vm.getBillId(),
        permanent: vm.type === 'permanent'
      })
          .then(function (response) {
            vm.loading = false;
            vm.data = response.data;
          }, function () {
            vm.loading = false;
          });
    },
    updateDataFromParams: function() {
      const vm = this;
      let apiKey = vm.$route.params.apiKey;
      let permanent = apiKey.indexOf('ro-') === -1;
      if(permanent) {
        //permanent bill, we expect uuid, this is billId and apiKey
        localStorage.apiKey = apiKey;
        //do not put ID to local storage
        localStorage.removeItem('billId');
        vm.permanentBillId = apiKey;
        vm.type = 'permanent';
      } else {
        //temporary bill
        localStorage.apiKey = apiKey;
        vm.permanentBillId = null;
        vm.type = 'temporary';
      }
      vm.apiKey = localStorage.apiKey;
    },
    checkTime: function() {
      const vm = this;
      if(vm.type==='permanent') {
        //limit time, when we have access to bill
        if(Date.now() - vm.permanentBillStartTime > 5*60*1000) {
          vm.$router.push('/pbe');
        }
      }
    },
    updateTime: function() {
      //prolong access time
      const vm = this;
      if(vm.type==='permanent') {
        vm.permanentBillStartTime=Date.now();
      }
    },
    getBillId: function() {
      const vm = this;
      if(vm.type==='permanent') {
        return vm.permanentBillId;
      }
      if(vm.type==='temporary') {
        return localStorage.billId;
      }
      return null;
    },
    prepareOrder: function (item) {
      this.itemToOrder = item;
      this.modifiers = JSON.parse(JSON.stringify(this.itemToOrder.modifiers)); //deep copy
      this.note = '';
      this.quantityToOrder = 1;
      this.state = 'order';
      this.updateTime();
    },
    changeQuantity: function (change) {
      this.quantityToOrder = this.quantityToOrder + change;
      if (this.quantityToOrder < 1) {
        this.quantityToOrder = 1;
      }
      if (this.quantityToOrder > 10) {
        this.quantityToOrder = 10;
      }
      this.updateTime();
    },
    typeExists: function(arr,type) {
       for(let i=0;i<arr.length;i++) {
         if(arr[i].orderState===type) {
           return true;
         }
       }
      return false;
    },
    makeOrder: function () {
      const vm = this;
        vm.updateDataFromParams();
        vm.checkTime();
        vm.updateTime();
        vm.loading = true;
        this.$http.post('/remote-order/make-order-bill', {
          billId: vm.getBillId(),
          itemId: vm.itemToOrder.itemId,
          amount: vm.quantityToOrder,
          modifiers: vm.modifiers,
          note: vm.note,
          permanent: vm.type === 'permanent'
        })
            .then(function (response) {
              vm.loading = false;
              if(vm.type==='temporary') {
                localStorage.billId = response.data.billId;
              }
              vm.state = 'list';
              vm.showConfirm = true;
              vm.resetTimeout();
              vm.refresh();
              vm.setCounter();
            }, function () {
              vm.loading = false;
            });
    },
    ping: function () {
      const vm = this;
      vm.checkTime();
      if (vm.getBillId()!== null && localStorage.apiKey !== null && vm.state === 'list' && vm.data.billExists === true) {
        this.$http.post('/remote-order/bill-ping', {
          billId: vm.getBillId(),
          lastChange: vm.data.billState.lastChange,
          billNum: vm.data.billState.billNum
        }).then(function (response) {
              if(response.data.refreshRequired === true) {
                vm.startTime = Date.now();
                vm.resetTimeout();
                vm.refresh();

                try {
                  const canVibrate = window.navigator.vibrate
                  if (canVibrate) {
                    window.navigator.vibrate(500);
                  }
                } catch (e) {
                  //for sure, vibration is not important
                }

              }
          vm.adjustTimeout();
        },function() {
          //error
          vm.adjustTimeout();
        });
      } else {
        //not necessary now
        vm.adjustTimeout();
      }
    },
    resetTimeout : function() {
      this.timeout = 4*1000;
    },
    adjustTimeout : function() {
      const vm = this;
      let runTimeout = true;
      if(Date.now() - vm.startTime > 1000*60) {
        vm.timeout = 8*1000;
      }
      if(Date.now() - vm.startTime > 1000*60*5) {
        vm.timeout = 30*1000;
      }
      if(Date.now() - vm.startTime > 1000*60*10) {
        vm.timeout = 2*60*1000;
      }
      if(Date.now() - vm.startTime > 1000*60*30) {
        runTimeout = false;
      }
      if(runTimeout === true) {
        setTimeout(vm.ping, vm.timeout);
      }
    },
    setCounter: function() {
      const vm = this;
      setTimeout(function() {
        vm.showConfirm=false;
      },2000);
    }
  }
}
</script>

<style>
.ep-item-name {
  width: 10em;
  margin: 1em;
}

.ep-item-price {
  width: 4em;
  margin: 1em;
  text-align: right;
}

.logo {
  margin-bottom: 20px;
}

.total {
  font-size: large;
}

.title {
  font-size: x-large;
  text-align: center;
}
.title-bold {
  font-size: x-large;
  text-align: center;
  font-weight: bold;
}
.quantity {
  font-size: xx-large;
  font-weight: bold;
}
.billNum {
  width: fit-content;
 border-style: solid;
 padding: 10px;
 margin: 10px;
 font-size: xx-large;
}
.finished {
  border-style: solid;
  border-width: thin;
  padding: 3px;
  margin-bottom: 6px;
  background-color: #e2e2b7;
}
.notFinished {
  border-style: solid;
  border-width: thin;
  padding: 3px;
  margin-bottom: 6px;
}
.largeFont {
  font-size: xx-large
}
.ep-info {
  background-color: #e2e2b7;
  font-size: x-large;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
