<template>
    <div class="e-card e-rounding shadow-sm">
        <h1 class="h3">{{$t('title.remoteOrder')}}</h1>
        <h2 style="color: darkred">
          Naskenujte prosím znovu QR kód.
        </h2>

    </div>
</template>

<script>
    export default {
        name: 'NoData',
        data: function () {
            return {
            }
        }
    }
</script>
