import NoData from "@/components/NoData";
import Bill from "@/components/Bill";

const routes = [
    { path: '/', component: NoData },
    { path: '/tb/:apiKey', component: Bill },
    { path: '/pb/:apiKey', component: Bill },
    { path: '/pbe', component: NoData}
];

export default routes;