<template>
  <div id="app">
    <div class="e-container" style="touch-action: manipulation;">
      <div class="row flex-nowrap">
        <v-dialog/>

        <main class="d-flex flex-column flex-grow-1">

          <section class="flex-grow-1">
            <router-view></router-view>
          </section>

          <section class="e-header e-rounding py-3 shadow-sm">
            <div class="row px-4 px-md-5">
              <div class="text-right">
                <LocaleSwitcher />
              </div>
            </div>
          </section>

          <footer class="e-footer text-secondary">
            <div class="row">
              <div class="col-sm-12 col-lg-3 mb-3">
                <h6 class="text-uppercase">{{ $t('footer.poweredBy') }}</h6>
                <a href="https://www.elementarypos.com" class="d-block text-secondary">
                  <img src="./assets/elementaryPOS.svg" width="250" class="d-block mx-auto mylogo">
                  www.elementarypos.com
                </a>
              </div>
            </div>
            <small class="text-secondary">© 2023 Copyright: eetplus s.r.o.</small>
          </footer>

        </main>
      </div>
    </div> <!-- .e-container -->
  </div> <!-- #app -->

</template>

<script>
 import LocaleSwitcher from "@/components/LocaleSwitcher";

  export default {
    name: 'App',
    components: {LocaleSwitcher},

    data: function () {
      return {
      }
    },
  }
</script>

<style>
  .tooltip {
    display: block !important;
    z-index: 10000;
  }

  .tooltip .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.65);
  }

  .tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }

  /* CUSTOM STYLES - classes with prefix "e-" */
  .e-bg-light {
    background-color: #f0f0f0;
  }

  .e-container {
    width: 100%;
    max-width: 600px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;
  }

  .e-drawer {
    display: flex;
    flex-direction: column;
    width: 260px;
    min-width: 260px;
    height: calc(100% - 2rem);
    min-height: 740px; /* need to be updated when there is new item in menu */
    margin: 1rem .5rem;
  }

  .e-menu__icon {
    display: none;
  }

  .e-menu-close__icon {
    display: none;
    color: white;
    width: 45px;
    padding: 1rem;
    padding-bottom: .25rem;
  }

  .e-backoffice {
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    border-bottom: 1px solid white;
    text-align: center;
    padding: 1rem .5rem;
  }

  .e-drawer-nav {
    display: flex;
    flex-direction: column;
    padding: .7rem;
    padding-right: 0;
  }

  .e-drawer-item {
    display: block;
    color: #fffdfa;
    font-weight: 400;
    font-size: 1.1rem;
    padding: 1rem 2rem 1rem .7rem;
    padding-right: 2rem;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    transition: color .15s linear;
    margin-bottom: 2px;
  }

  .e-main {
    min-height: 100vh;
  }

  .e-header {
    background-color: white;
    margin: .5rem;
    margin-top: 1rem;
  }

  .e-card {
    position: relative;
    background-color: white;
    margin: .5rem;
    padding: 2rem;
    min-height: 99%;
  }

  .e-inner-card {
    height: 100%;
  }

  .e-card h1 {
    margin-bottom: 1.5rem;
  }

  .e-footer {
    text-align: center;
    margin-top: .5rem;
/*     margin-right: .5rem;
    margin-left: .5rem; */
    padding: 2rem;
    padding-bottom: 1rem;
  }

  .e-shadow {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .e-rounding {
    border-radius: .5rem;
  }

  .e-card-backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .e-modal-row {
    height: 100%;
    overflow: auto;
  }

  .e-modal-card {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .e-modal-close-icon {
    position: absolute;
    right: 16px;
    top: calc(50% - 16px);
    cursor: pointer;
    padding: 8px;
    transition: color .15s linear;
  }

  .e-modal-close-icon:hover {
  color: #6c757d;
  }

  .e-not-active {
    color: #7E7E7E;
  }

  .e-user__header {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }

  .e-user__header i {
    font-size: 1rem;
  }

  .e-user__header.user_admin {
    background-color: #97bdce;
  }

  .e-user__header.e-not-active {
    background-color: rgba(0, 0, 0, .03);
  }

  .e-detail-title {
    font-weight: 600;
    font-size: .9rem;
  }

  .e-table-link {
    color: #212529;
    font-weight: 600;
  }

  .e-table-simple-link {
    color: #212529;
  }

  .e-table-link:hover {
    color: #212529;
    text-decoration: underline;
    cursor: pointer;
  }

  .e-table-simple-link:hover {
    color: #212529;
    text-decoration: underline;
    cursor: pointer;
  }

  .e-survey__container {
    color: #7E7E7E;
    margin-right: 50px;
  }

  .e-link-secondary {
    color: #7E7E7E;
    text-decoration: underline;
  }

  .e-link-secondary:hover {
    color: #7E7E7E;
    text-decoration: none;
  }

  @media screen and (max-width: 992px) {
    .e-drawer {
      display: none;
      position: fixed;
      width: 100%;
      z-index: 1000;
      margin: 0;
      min-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .e-main {
      position: absolute;
      top: 0;
      left: 0;
      overflow-x: hidden;
      max-width: 100%;
    }

    .e-drawer-nav {
      flex-grow: 0 !important;
    }

    .e-menu__icon {
      display: block;
    }

    .e-mobile-nav .e-drawer {
      display: flex !important;
    }

    .e-mobile-nav .e-menu-close__icon {
      display: inline;
    }

    .e-backoffice {
      padding-top: 0;
    }

    .e-card {
      padding: 2rem 1rem;
    }

    .e-card h1 {
      font-size: 1.5rem;
    }

    .e-rounding:not(.e-modal-card) {
      border-radius: .25rem;
    }

    .e-rounding.e-drawer {
      border-radius: 0;
    }

    .e-login-card {
      margin: .5rem auto;
    }

    .e-login__ls {
      margin-right: auto;
      margin-left: auto;
    }

    .e-logo img {
      width: 140px;
    }

    .e-survey__container {
      order: 10;
      width: 100%;
      text-align: center;
      padding-top: 15px;
    }
  }

  @media screen and (max-width: 767px) {

  }


  @media screen and (max-width: 576px) {
    .e-btn-sm-block {
      display: block;
      width: 100%;      
    }
  }

  /* CUSTOMIZE BOOTSTRAP STYLES */
  .text-secondary {
    color: #7E7E7E !important;
  }

  .text-dark {
    color: #4D535A !important;
  }

  .text-body {
    color: #212529 !important;
  }

  .text-success {
    color: #419c30 !important;
  }

  .text-warning {
    color: darkorange !important;
  }

  .text-primary {
    color: #19415b !important;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  .bg-primary {
    background-color: #19415b !important;
  }

  .bg-warning {
    background-color: rgba(222, 210, 49, .6) !important;
  }

  .btn {
    white-space: nowrap;
  }

  .btn.btn-primary,
  .btn.btn-primary:hover,
  .btn.btn-primary:active {
    background-color: #19415b !important;
    border-color: #19415b !important;
    color: white !important;
    font-weight: 500;
  }

  .btn.btn-success,
  .btn.btn-success:hover,
  .btn.btn-success:active {
    background-color: #419c30 !important;
    border-color: #419c30 !important;
    color: white !important;
    font-weight: 500;
  }

  .btn.btn-outline-primary,
  .btn.btn-outline-primary:hover,
  .btn.btn-outline-primary:active {
    border-color: #19415b !important;
    color: #19415b !important;
    background-color: white !important;
    font-weight: 500;
  }

  .btn.btn-danger {
    background-color: #f14242;
    border-color: #f14242;
    color: white;
    font-weight: 500;
  }

  .card-header {
    border-bottom: none !important;
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
    /* important for close icon: */
    position: relative;
    padding-right: 50px;
    padding-left: 50px;
  }

  label {
    font-weight: 600;
  }

  .py-5 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }

  pre {
    white-space: normal;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0,0,0,.03);
  }

  .mylogo {
    margin: 10px;
  }
</style>
